html {
    /*padding-top: 65px;*/
    /* min-width: 250px;
    height: 100%;
    */
    /* background-image: url('https://www.netpremacy.com/wp-content/uploads/2018/09/Background-website-01.jpg');  */
    
    margin: 0;
    padding: 0;
}

body {
    margin: 0;
    padding: 0;
    height: 100vh;
    background-image: url('https://www.netpremacy.com/wp-content/uploads/2018/09/Background-website-01.jpg');
}

#root {
    display: flex;
    flex-direction: column;
    align-content: space-between;
    margin: 0;
    padding-top: 65px;
    height: 100%;
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');